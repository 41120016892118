type Props = {
  children: React.ReactNode;
  text: string;
  bottom?: boolean;
};

export function Tooltip({ children, text, bottom }: Props) {
  return (
    <div className="group relative">
      {children}
      <span
        className={`absolute ${
          bottom ? "top-10" : "bottom-10"
        } scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100`}
      >
        {text}
      </span>
    </div>
  );
}
