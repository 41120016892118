import React, { useEffect, useRef } from "react";
import { Store } from "../../helpers";
import { AssetUploadStatusItem } from "./AssetUploadStatusItem";
import {
  ShowAssetUploadState,
  UploadedFileState,
  UploadState,
  CurrentCustomerAssetSizeState,
} from "../../atoms";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import * as tus from "tus-js-client";
import { baseURL } from "../../constants";
import { UploadFile } from "../Svgs";
import { PreviousUpload } from "tus-js-client";
import { v4 as uuidv4 } from "uuid";

type Props = {
  onSuccess: () => void;
};

interface UploadStateWithUploader {
  id?: string | null;
  customerId: string;
  index: number;
  fileName: string;
  fileSize: number;
  percentCompleted: number;
  isFinished: boolean;
  isCanceled: boolean;
  isQued: boolean;
  upload?: tus.Upload;
}

export function AssetUpload({ onSuccess }: Props) {
  const [uploadedFiles, setUploadedFiles] = useRecoilState(UploadedFileState);
  const [showAssetUpload] = useRecoilState(ShowAssetUploadState);
  const [currentCustomerAssetSize] = useRecoilState(
    CurrentCustomerAssetSizeState
  );

  let newUploadedFiles = useRef<UploadStateWithUploader[]>([]);
  const currentCustomer = Store.getCurrentCustomer();

  useEffect(() => {
    newUploadedFiles.current = uploadedFiles.map((x) => ({
      ...x,
    })) as UploadStateWithUploader[];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadedFiles.length === 0) {
      newUploadedFiles.current = [];
    }
  }, [uploadedFiles]);

  const validateStorageLimit = (cumulativeFileSize: number, file: File) => {
    if (currentCustomer?.storageLimit) {
      let storageLimitInBytes =
        currentCustomer.storageLimit * 1024 * 1024 * 1024;

      if ((currentCustomerAssetSize + cumulativeFileSize) > storageLimitInBytes) {
        toast.error(
          `Upload for ${file.name} failed.
          You have reached your storage limit of ${currentCustomer.storageLimit} GB.
          Please remove assets to free up space or contact customer service to request an upgrade of your storage capacity.`
        );
        return false;
      }
    }

    return true;
  };

  const fileuploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    event.preventDefault();

    let cumulativeFileSize = 0;
    let files: File[] = [];
    if (event.target.files) files = Array.from(event.target.files);

    files.forEach((uploadedFile: File) => {
      if (
        validateStorageLimit(
          cumulativeFileSize + uploadedFile.size,
          uploadedFile
        )
      ) {
        cumulativeFileSize += uploadedFile.size;
        uploadFile(uploadedFile);
      }
    });
  };

  const handleCancel = (index: number) => {
    let canceledUpload = newUploadedFiles.current.find(
      (x) => x.index === index
    );

    if (!canceledUpload) return;

    canceledUpload.upload?.abort(false);
    canceledUpload.isFinished = false;
    canceledUpload.isQued = false;
    canceledUpload.isCanceled = true;

    canceledUpload.upload?.abort(false);

    canceledUpload &&
      newUploadedFiles.current.splice(canceledUpload.index, 1, canceledUpload);
    startNextUpload();
  };

  const handleResume = (index: number) => {
    let canceledUpload = newUploadedFiles.current.find(
      (x) => x.index === index
    );
    if (!canceledUpload) return;

    canceledUpload.isCanceled = false;
    canceledUpload.isQued = true;

    newUploadedFiles.current.splice(canceledUpload.index, 1, canceledUpload);

    startNextUpload();
  };

  const getFileType = (uploadedFile: File): string => {
    let fileExtenstion = uploadedFile.name.split(".").at(-1)?.toLowerCase();
    if (fileExtenstion === "mpv") return "video/mpeg";
    if (fileExtenstion === "mxl") return "video/mxl";
    return uploadedFile.type;
  };

  const isFileSupported = (file: File) => {
    const types = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
      "video/quicktime",
      "video/x-msvideo",
      "video/mp4",
      "video/avi",
      "video/mpeg",
      "video/x-matroska",
      "video/mxl",
    ];
    const unsupportedExtensions = ["jfif"];

    const fileExtenstion = file.name.split(".").at(-1)?.toLowerCase();

    if (unsupportedExtensions.some((ext) => ext === fileExtenstion)) {
      return false;
    }

    const fileType = getFileType(file);

    if (types.every((type) => type !== fileType)) {
      return false;
    }

    return true;
  };

  const uploadFile = (uploadedFile: File) => {
    if (!isFileSupported(uploadedFile)) {
      toast.error(`Unsupported File Type for ${uploadedFile.name}`);
      return;
    }

    let newUploadedState = {
      id: null,
      customerId: currentCustomer?.id,
      index: newUploadedFiles.current.length,
      fileName: uploadedFile.name,
      fileSize: uploadedFile.size,
      isFinished: false,
      percentCompleted: 0,
    };

    let file = uploadedFile;

    // Create a new tus upload
    let upload = new tus.Upload(file, {
      endpoint: baseURL + "/asset/upload",
      chunkSize: 20 * 1024 * 1024,
      retryDelays: [500, 3000, 5000, 10000, 20000, 40000, 60000, 120000],

      metadata: {
        generatedName: uuidv4().replaceAll("-", ""),
        customerId: currentCustomer?.id ?? "",
        filename: file.name,
        filetype: getFileType(file),
        userName: Store.getLoggedInUser()?.email ?? "",
      },
      onError: function (error) {
        console.log("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentCompleted = (bytesUploaded / bytesTotal) * 100;
        let data = newUploadedFiles.current.find(
          (x) => x.index === newUploadedState.index
        );
        const indexData = {
          ...data,
          percentCompleted: percentCompleted,
        } as UploadStateWithUploader;
        indexData &&
          newUploadedFiles.current.splice(indexData.index, 1, indexData);
        setUploadedFiles([
          ...newUploadedFiles.current.map((u) => convertToUploadState(u)),
        ]);
      },
      onSuccess: function () {
        const indexData = {
          ...newUploadedFiles.current.find(
            (x) => x.index === newUploadedState.index
          ),
          isFinished: true,
        } as UploadStateWithUploader;

        toast.success(`${indexData.fileName} uploaded successfully`);

        indexData &&
          newUploadedFiles.current.splice(indexData.index, 1, indexData);

        startNextUpload();
      },
    });
    newUploadedFiles.current.push({
      ...newUploadedState,
      upload: upload,
    } as UploadStateWithUploader);
    setUploadedFiles([
      ...newUploadedFiles.current.map((u) => convertToUploadState(u)),
    ]);

    // Check if there are any previous uploads to continue.

    if (
      newUploadedFiles.current.filter(
        (x) => !x.isFinished && !x.isCanceled && !x.isQued
      ).length < 4
    ) {
      upload.findPreviousUploads().then((previousUploads: PreviousUpload[]) => {
        let previousUpload = previousUploads.find(
          (u) => u.size === file.size && u.metadata["filename"] === file.name
        );
        if (previousUpload) {
          upload.resumeFromPreviousUpload(previousUpload);
        }
        upload.start();
      });
    } else {
      const indexData = {
        ...newUploadedFiles.current.find(
          (x) => x.index === newUploadedState.index
        ),
        isQued: true,
      } as UploadStateWithUploader;
      indexData &&
        newUploadedFiles.current.splice(indexData.index, 1, indexData);
      setUploadedFiles([
        ...newUploadedFiles.current.map((u) => convertToUploadState(u)),
      ]);
    }
  };

  const startNextUpload = () => {
    let inProgressUploadCount = newUploadedFiles.current.filter(
      (x) => !x.isFinished && !x.isCanceled && !x.isQued
    ).length;

    let remainingUploadCount = newUploadedFiles.current.filter(
      (x) => !x.isFinished && !x.isCanceled && x.isQued
    ).length;

    if (inProgressUploadCount < 4 && remainingUploadCount > 0) {
      let nextUploadedFile = newUploadedFiles.current.filter(
        (x) => !x.isFinished && !x.isCanceled && x.isQued
      )[0];

      const nextUploadedFileData = {
        ...nextUploadedFile,
        isQued: false,
      } as UploadStateWithUploader;

      nextUploadedFileData &&
        newUploadedFiles.current.splice(
          nextUploadedFileData.index,
          1,
          nextUploadedFileData
        );
      nextUploadedFile?.upload
        ?.findPreviousUploads()
        .then((previousUploads: PreviousUpload[]) => {
          let previousUpload = previousUploads.find(
            (u) =>
              u.size === nextUploadedFile.fileSize &&
              u.metadata["filename"] === nextUploadedFile.fileName
          );
          if (previousUpload) {
            nextUploadedFile?.upload?.resumeFromPreviousUpload(previousUpload);
          }
          nextUploadedFile?.upload?.start();
        });
    }

    setUploadedFiles([
      ...newUploadedFiles.current.map((u) => convertToUploadState(u)),
    ]);
  };

  const convertToUploadState = (u: UploadStateWithUploader) => {
    return {
      fileName: u.fileName,
      fileSize: u.fileSize,
      index: u.index,
      isFinished: u.isFinished,
      isCanceled: u.isCanceled,
      isQued: u.isQued,
      percentCompleted: u.percentCompleted,
      id: u.id,
      customerId: u.customerId,
      handleCancel: handleCancel,
    } as UploadState;
  };

  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    let cumulativeFileSize = 0;

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          const uploadedFile = e.dataTransfer.items[i].getAsFile();
          if (uploadedFile) {
            if (
              validateStorageLimit(
                cumulativeFileSize + uploadedFile.size,
                uploadedFile
              )
            ) {
              cumulativeFileSize += uploadedFile.size;
              uploadFile(uploadedFile);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const uploadedFile = e.dataTransfer.files[i];
        if (uploadedFile) {
          if (
            validateStorageLimit(
              cumulativeFileSize + uploadedFile.size,
              uploadedFile
            )
          ) {
            cumulativeFileSize += uploadedFile.size;
            uploadFile(uploadedFile);
          }
        }
      }
    }
  };

  return (
    <div className="h-full overflow-auto p-5 text-white">
      <div className="flex flex-col h-full cursor-pointer">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragDrop}
          className="h-full p-6 border-2 border-dashed rounded-md border-page-border"
        >
          <form>
            <label className="flex flex-col h-full justify-center">
              <div className="flex items-center justify-center flex-col gap-2 my-2">
                <input
                  onChange={fileuploadHandler}
                  className="invisible w-0 h-0"
                  type="file"
                  multiple
                />
                <span className="w-14 h-14 flex justify-center items-center rounded-full bg-white bg-opacity-20">
                  <UploadFile />
                </span>
                <p>
                  <span className="text-blue-500 pr-1">Click to upload</span>
                  <span>or drag and drop assets</span>
                </p>
                <p className="opacity-50">
                  Supports Mpeg2, PNG, JPG, NotchLC & HAP
                </p>
                <p className="opacity-50">
                  * MP4 is not recommended *
                </p>
              </div>
            </label>
          </form>
        </div>
        <div
          className={`mt-6 ${
            uploadedFiles.length > 0 && showAssetUpload
              ? "visible"
              : "invisible"
          }`}
        >
          <p>UPLOADED</p>
          <ul>
            {newUploadedFiles.current
              .filter((x) => x.customerId === currentCustomer?.id)
              .map((x) => (
                <AssetUploadStatusItem
                  key={x.index}
                  {...x}
                  handleCancel={handleCancel}
                  handleResume={handleResume}
                />
              ))}
          </ul>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onSuccess}
            className="py-2.5 px-4 rounded-md bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary"
          >
            Go to assets
          </button>
        </div>
      </div>
    </div>
  );
}
