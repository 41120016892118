import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";
import { Store } from ".";
import { baseURL } from "../constants/ApiConstans";

export function GetNewInstance(): AxiosInstance {
  const token = Store.getToken();

  const api = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress(progressEvent: ProgressEvent) {
      const progress = progressEvent.loaded / progressEvent.total;
      console.log(progress);
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //const expectedError =
      //  error.response &&
      //  error.response.status >= 400 &&
      //  error.response.status < 500;

      if (error.response.status === 401) {
        window.location.href = "/login";
        return;
      }
      // Handle API Error and display the error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Oops something went wrong");
      }
      return Promise.reject(error);
    }
  );

  return api;
}
