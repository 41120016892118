import { Tooltip } from "../Misc/Tooltip";

export enum ButtonType {
  Primary,
  Secoundary,
  Destructive,
  Link,
}

export enum IconStyle {
  None,
  Left,
  Right,
  Only,
}

type Props = {
  button_type?: ButtonType;
  icon_style?: IconStyle;
  icon?: JSX.Element;
  onClick?: () => void;
  children?: JSX.Element | string;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipBottom?: boolean;
};

export function AppButton({
  button_type = ButtonType.Primary,
  icon_style = IconStyle.None,
  icon,
  onClick,
  className,
  children,
  disabled,
  tooltip,
  tooltipBottom
}: Props) {
  const renderButton = () => {
    return (
        <button onClick={onClick} disabled={disabled} className={`${className} pt-3 pb-3 pr-5 pl-5 rounded-md flex items-center gap-2
                            active:
                            disabled:opacity-40
                            ${ButtonType.Primary === button_type ? 'bg-button-bg-primary-default hover:bg-button-bg-primary-hover active:shadow-button_primary' : ''} 
                            ${ButtonType.Secoundary === button_type ? 'bg-button-bg-secoundary  active:shadow-button_secoundary border border-splitter-button-secoundary-default hover:border-button-border-secoundary-hover active:hover:border-button-border-secoundary-active disabled:hover:border-button-border-secoundary-disabled' : ''}
                            ${ButtonType.Destructive === button_type ? 'bg-button-bg-destructive-default hover:bg-button-bg-destructive-hover active:shadow-button_destructive' : ''}  `}>

            {icon_style === IconStyle.Left &&
                <>
                    {icon}
                    {children}
                </>
            }
            {icon_style === IconStyle.Right &&
                <>
                    {children}
                    {icon}
                </>
            }
            {icon_style === IconStyle.None &&
                <>{children}</>
            }
            {icon_style === IconStyle.Only &&
                <>{icon}</>
            }
        </button>
    );
  };
  return (
    tooltip
    ? (<Tooltip text={tooltip} bottom={tooltipBottom}>
        {renderButton()}
    </Tooltip>)
    : renderButton()
  );
}
