import { BaseModal } from "./BaseModal";
import { UpdateCanvasForm } from "../form";
import { ModalTypes } from "./ModalTypes";
import { CanvasListDto } from "../../services";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
  canvases: CanvasListDto[];
};

export function UpdateCanvasModal({ onSuccess, onFailure, canvases }: Props) {
  return (
    <BaseModal
      id={ModalTypes.UpdateCanvasModal}
      title="Update canvas"
      description="Update the canvas information"
    >
      <UpdateCanvasForm
        onSuccess={onSuccess}
        onFailure={onFailure}
        canvases={canvases}
      />
    </BaseModal>
  );
}
