import { BaseModal } from "./BaseModal";
import { AddCanvasForm } from "../form";
import { ModalTypes } from "./ModalTypes";
import { CanvasListDto } from "../../services";

type Props = {
  onSuccess: () => void;
  onFailure: () => void;
  canvases: CanvasListDto[];
};

export function AddCanvasModal({ onSuccess, onFailure, canvases }: Props) {
  return (
    <BaseModal
      id={ModalTypes.AddCanvasModal}
      title="Add canvas"
      description="Add a canvas to display assets and playlists on it."
    >
      <AddCanvasForm
        onSuccess={onSuccess}
        onFailure={onFailure}
        canvases={canvases}
      />
    </BaseModal>
  );
}
